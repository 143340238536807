import { useState, useEffect, useRef } from "react";
import "./Primer.css";
import { GoCheckCircleFill } from "react-icons/go";
import { Link } from "react-router-dom";
import BackgroundImage10 from "../../assist/background10.png";
import BackgroundImage1 from "../../assist/background1.png";
import IMG1 from "../../assist/IMG1.png";
import IMG2 from "../../assist/IMG2.png";
import IMG3 from "../../assist/IMG3.png";

const Primer = () => {
  const [isPro, setIsPro] = useState(true);

  const handleToggle = () => {
    setIsPro((prevState) => !prevState);
  };

  const [isVisible, setIsVisible] = useState(false);
  const roadmapRef = useRef(null); // Create a reference using useRef

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Optional: disconnect once it's visible
        }
      },
      { threshold: 0.5 } // Adjust threshold to 50% of element visibility
    );

    if (roadmapRef.current) {
      observer.observe(roadmapRef.current);
    }

    return () => {
      if (roadmapRef.current) {
        observer.unobserve(roadmapRef.current);
      }
    };
  }, []);

  const cardTow = [
    {
      Pay: "Pay in Full",
      Get: "Get 10% Off In Full Pay",
      dollar: `${isPro ? "2900" : "1900"}`,
      Weeks: "One and Done",
    },
    {
      Pay: "Payment Plan #2",
      Get: "Installments",
      dollar: `${isPro ? "1450" : "950"}`,
      Weeks: "50% Now 50% In30Days",
    },
    {
      Pay: "Payment Plan #3",
      Get: "6 Weekly Payments",
      dollar: `${isPro ? "483" : "317"}`,
      Weeks: "Every 2 Weeks",
    },
  ];

  return (
    <div className="Primer">
      <div className="mainPrimer">
        <h6 className={`fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}>
          Are you ready to join us?
        </h6>
        <h2 className={`fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}>
          A Legal Primer on Residency and{" "}
          <span>
            Business <br /> in Africa
          </span>
        </h2>
        <p className={`fade-in ${isVisible ? "show" : ""}`} ref={roadmapRef}>
          {isPro
            ? "Unlock exclusive benefits with the Premium Package! Gain direct access to Global Esquire's network of local attorneys across Africa, along with dedicated office hours and interactive workshops hosted by expert lawyers. This is your chance to get personalized legal guidance and connect with seasoned professionals to navigate every aspect of African law with ease and confidence.  :"
            : "For those who enroll in the Standard package, you will gain exclusive access to the Standard Legal Access.  "}
        </p>
        <div className="switch-container">
          <span className={!isPro ? "active-label" : ""}>Standard</span>
          <label className="switch">
            <input type="checkbox" checked={isPro} onChange={handleToggle} />
            <span className="slider round"></span>
          </label>
          <span className={isPro ? "active-label" : ""} id="Premium">
            Premium
          </span>
        </div>
        <h3 className="Attorneys">
          {isPro
            ? "Access to Local Attorneys in Africa"
            : "Access to Local Attorneys in Africa"}
        </h3>
        <div className={isPro ? "activeCards" : "cards"}>
          <div className="card">
            <h3>{isPro ? "Premium Primer " : "Standard"}</h3>
            <h4>
              {isPro ? "Premium Legal Experience" : "Standard Legal Experience"}
            </h4>
            <h1>
              <span>{isPro ? "$2,900" : "$1,900"}</span>
            </h1>
            <p className="line-through">
              {isPro ? "Compared to $10,000 USD in Legal Fees" : ""}
            </p>
            <p> {isPro ? "" : "Compared to $5,000 USD in Legal Fees"}</p>
            <ul className="list">
              <div className="secondCards">
                <h5>
                  {isPro
                    ? "Save on Costs Compared to Our Standard Hourly Rates Africa Immigration Law $300.00 compared to law firm $450/hr fee"
                    : "Save on Costs Compared to Our Standard Hourly Rates Africa Legal Hourly Rate $450/hour"}
                </h5>

                <div className="images">
                  <h6 className="LegalMsg topborder">
                    {isPro ? "Africa Legal Master Class" : ""}
                  </h6>
                  <img src={isPro ? IMG1 : BackgroundImage10} alt="image" />
                  <h6>{isPro ? "Legal Blueprints Guides" : ""}</h6>
                  <img src={isPro ? IMG2 : BackgroundImage1} alt="image" />
                  <h6 className="LegalMsg">
                    {isPro ? "Africa Legal Alliance" : ""}
                  </h6>
                  <img
                    src={isPro ? IMG3 : ""}
                    alt="image"
                    className="imageNone"
                  />
                </div>

                <h3>Payment Plans</h3>
                <div className="secondCard">
                  {cardTow.map((data, index) => (
                    <div className="cardTow" key={index}>
                      <h4>{data?.Pay}</h4>
                      <h6>{data?.Get}</h6>
                      <h2>
                        <span>$</span>
                        {data?.dollar}
                      </h2>
                      <p>{data?.Weeks}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Biweekly 45-minute office hours with Attorney Tareian King, ESQ."
                      : "Weekly live sessions:"}
                  </span>
                  <br />
                  {isPro
                    ? "providing personalized legal advice to address your unique concerns."
                    : "For direct, real-time guidance from a legal expert with extensive experience in U.S.-Africa relations, helping you stay ahead of any challenges."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Weekly live sessions:"
                      : "Africa Legal Insight Replays"}
                  </span>
                  <br />
                  {isPro
                    ? " For direct, real-time guidance from a legal expert with extensive experience in U.S.-Africa relations, helping you stay ahead of any challenges."
                    : "access to replays of weekly live sessions covering essential topics tailored to Africa. These include pre-arrival considerations, choosing the right country, immigration law, family law, business and real estate law, and criminal law, offering the insights you need to navigate life and opportunities on the continent with confidence."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Africa Legal Insight Replays"
                      : "Legal appendix packed with essential resources"}
                  </span>
                  <br />
                  {isPro
                    ? " access to replays of weekly live sessions covering essential topics tailored to Africa. These include pre-arrival considerations, choosing the right country, immigration law, family law, business and real estate law, and criminal law, offering the insights you need to navigate life and opportunities on the continent with confidence."
                    : "Including embassy contacts, emergency numbers, and key documents, ensuring you're fully equipped for your transition."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Direct Access to the Africa Legal Alliance"
                      : "Real-world case studies"}
                  </span>
                  <br />
                  {isPro
                    ? " Connect with an elite network of lawyers, realtors, and local experts who will support you through every stage of your relocation or business venture."
                    : "Showcasing successful strategies used by others, so you can learn from proven examples."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro
                      ? "Legal appendix packed with essential resources "
                      : "Interactive assignments"}
                  </span>
                  <br />
                  {isPro
                    ? " Including U.S. embassy contacts, emergency numbers, and key documents, ensuring you're fully equipped for your transition "
                    : "To help you apply Africa-specific legal concepts and get real-time feedback from experts."}
                </li>
              </div>
              <div className="li">
                <span>
                  <GoCheckCircleFill />
                </span>
                <li>
                  <span>
                    {isPro ? "Real-world case studies" : "Tailored solutions:"}
                  </span>
                  <br />
                  {isPro
                    ? "Showcasing successful strategies used by others, so you can learn from proven examples."
                    : "To all your legal needs—whether it's securing residency, buying property, or setting up a business in Africa—ensuring you’re not just prepared, but confident every step of the way."}
                </li>
              </div>
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Interactive assignments" : ""}</span>
                  <br />
                  {isPro
                    ? "To help you apply Africa-specific legal concepts and get real-time feedback from experts"
                    : ""}
                </li>
              </div>
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Workshops:" : ""}</span>
                  <br />
                  {isPro
                    ? "With local experts from your selected country, offering insider insights to help you navigate the on-the-ground realities of doing business or relocating."
                    : ""}
                </li>
              </div>
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Tailored solutions" : ""}</span>
                  <br />
                  {isPro
                    ? "To all your legal needs—whether it's securing residency, buying property, or setting up a business in Africa—ensuring you’re not just prepared, but confident every step of the way."
                    : ""}
                </li>
              </div>
              {/* <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Tailored solutions" : ""}</span>
                  {isPro
                    ? " To all your legal needs—whether it's securing residency, buying property, or setting up a business in Africa—ensuring you’re not just prepared, but confident every step of the way."
                    : ""}
                </li>
              </div> */}
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>{isPro ? "Africa Legal Blueprints" : ""}</span>
                  <br />
                  {isPro
                    ? "Unlock seamless access to life and business in The Gambia, Tanzania, South Africa, Sierra Leone, Kenya, Senegal, Nigeria, Ghana, Egypt, and Uganda with the Africa Legal Blueprints. Designed for individuals and businesses, these premium guides provide expert insights on immigration, company registration, real estate acquisition, family law, and criminal justice—ensuring you navigate every legal process with confidence and ease."
                    : ""}
                </li>
              </div>
              <div className="li disable">
                <span>{isPro ? <GoCheckCircleFill /> : ""}</span>
                <li>
                  <span>
                    {isPro ? "Africa Real Estate Due Diligence Blueprint" : ""}
                  </span>
                  <br />
                  {isPro
                    ? "Go-to guide for safe and successful property investments in Africa. It covers key steps like verifying ownership, understanding property laws, checking zoning regulations, and avoiding common risks. Whether you're buying land, a home, or a commercial property, this blueprint helps you make informed decisions and protect your investment with confidence."
                    : ""}
                </li>
              </div>
            </ul>
            <Link to="/FormsOne">
              <button>GET ACCESS NOW</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Primer;
