import React from "react";
import "./Testimonials.css";
import { BsQuote } from "react-icons/bs";
// import TestimonialVideoImage from "../../assist/TestimonialVideoImage.png";
// import MalajaJImage from "../../assist/MalajaJImage.png";
// import RenataBImage from "../../assist/RenataBImage.png";
// import Testvideo1Image from "../../assist/Testvideo1Image.png";
// import Testvideo1 from "../../assist/testvideo1.mp4";
// import MalajaJ from "../../assist/MalajaJ.mp4";
// import RenataB from "../../assist/RenataB.mp4";
// import TestimonialVideo from "../../assist/TestimonialVideo.mp4";
import LawFirmReel1 from "../../assist/LawFirmReel1.mp4";
import LawFirmReel2 from "../../assist/LawFirmReel2.mp4";
import LawFirmReel3 from "../../assist/LawFirmReel3.mp4";
import LawFirmReel4 from "../../assist/LawFirmReel4.mp4";
import LawFirmReel5 from "../../assist/LawFirmReel5.mp4";
import LawFirmReel6 from "../../assist/LawFirmReel6.mp4";

const Results = [
  {
    userName: "Malajai Bailey",
    ceo: "Native of Atlanta, Georgia",
    // image: TestimonialVideoImage,
    video: LawFirmReel1,
  },
  {
    userName: "Dr. Renata Burgess-Brigham",
    ceo: "Native of Houston, Texas",
    // image: RenataBImage,
    video: LawFirmReel2,
  },
  {
    userName: "Dexter R.",
    ceo: "Native of Birmingham, Alabama",
    // image: MalajaJImage,
    video: LawFirmReel3,
  },
  {
    userName: "Derrick Johnson",
    ceo: "Native of Atlanta, Georgia",
    // image: Testvideo1Image,
    video: LawFirmReel4,
  },
  {
    userName: "Monique W.",
    ceo: "Native of Washington, DC",
    // image: Testvideo1Image,
    video: LawFirmReel5,
  },
  {
    userName: "Yolanda G.",
    ceo: "Native of New Orleans, Louisiana",
    video: LawFirmReel6,
  },
];

const Testimonials = () => {
  return (
    <div className="Testimonials">
      <h3>
        <span></span>Testimonials
      </h3>
      <h4>
        Client Testimonials: Real <br /> Results, Real Feedback
      </h4>
      <div className="cards">
        {Results.map((data, index) => (
          <div className="card" key={index}>
            <span>
              <BsQuote size={56} />
            </span>
            <div className="video">
              <div className="image-container">
                <video className="backgroundVideo" controls>
                  <source src={data.video} type="video/mp4" />
                </video>
              </div>
              <div className="imgInfo">
                <div className="name">
                  <h2>{data.userName}</h2>
                  <h2 className="native">{data.ceo}</h2>
                </div>
                {/* <img src={data.image} alt={data.userName} /> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
