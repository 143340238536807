import React from "react";
import "./Personalized.css";
import Image1 from "../../assist/Image1.png";
import Image2 from "../../assist/Image2.png";
import Image3 from "../../assist/Image3.png";
import Image4 from "../../assist/Image4.png";
import Image5 from "../../assist/Image5.png";
import Image6 from "../../assist/Image6.png";
import Image7 from "../../assist/Image7.png";
import Image8 from "../../assist/Image8.png";
import Image9 from "../../assist/Image9.png";
import Image10 from "../../assist/Image10.png";

const Immigration = [
  {
    heading: "Immigration and Residency",
    para: "Expert assistance to navigate complex processes for residency, citizenship, and work authorization.",
  },
  {
    heading: "Business Strategy",
    para: "Tailored guidance on business formation, market entry, and regulatory compliance for African markets.",
  },
  {
    heading: "Real Estate Excellence",
    para: "Specialized support for property transactions, due diligence, and strategic investments.",
  },
  {
    heading: "Investment Insights:",
    para: "Exclusive advice on Africa’s emerging investment opportunities, risk mitigation, and wealth-building strategies.",
  },
];

const MwiteteCards = [
  {
    image: Image1,
    nam: "Moses Mwitete",
    republic: "Tanzania Legal Counsel",
    attorney: (
      <>
        Attorney Moses Mwitete is a seasoned legal professional based in
        Tanzania, specializing in corporate law, investment law, real estate,
        and commercial litigation. Admitted to the Tanganyika Roll of Advocates
        in 2018, he has established himself as a trusted advisor to businesses
        and individuals navigating complex legal frameworks. <br /> <br /> Moses
        has worked with the Attorney General’s Chambers, the Kibaha District
        Court, and NEBO & Co. Advocates, where he gained extensive experience in
        litigation, corporate governance, and compliance. Currently, as a Senior
        Associate at Godwin Attorneys, he provides strategic counsel on
        high-stakes corporate transactions and investment matters. He also
        advises on real estate and regulatory compliance for local and
        international clients. <br /> <br />A graduate of the University of Dar
        es Salaam and the Law School of Tanzania, Moses has enhanced his
        expertise with specialized training in intellectual property and
        innovation. Fluent in English and Swahili, he delivers effective legal
        solutions tailored to his clients’ needs. Now with Global Esquire Law
        Firm, Attorney Mwitete offers comprehensive support in corporate,
        investment, and real estate matters, helping clients achieve their
        business goals in Tanzania’s dynamic market.
      </>
    ),
  },
  {
    image: Image2,
    nam: "Rhandzu Rivoningo Rhulani Mbhambhu",
    republic: "South Africa Legal Counsel",
    attorney: (
      <>
        Attorney Rhandzu Rivoningo Rhulani Mbhambhu is an accomplished attorney
        admitted to the High Court of South Africa, holding a Bachelor of Laws
        from the University of Limpopo Law School. With a strong background in
        litigation and advisory services, she has significant expertise in real
        estate, business, and immigration law. Currently leading the Pretoria
        office at RRR Mbhambhu & Associates Inc., <br /> <br />
        Attorney Mbhambhu oversees complex legal operations, playing a pivotal
        role in shaping case strategies, conducting in-depth legal research, and
        providing sophisticated advisory services to both corporate and
        individual clients. Her real estate practice includes conducting
        meticulous due diligence for property transactions, ensuring compliance
        and safeguarding client interests. In business law, Attorney Mbhambhu
        provides legal counsel to small business owners in South Africa as well
        as foreign investors interested in establishing operations within the
        country. <br /> <br />
        She advises on corporate matters, contract negotiations, and debt
        management. Her immigration work includes guiding clients through
        regulatory compliance and supporting individuals and businesses in
        navigating complex legal processes. A member of the Legal Practice
        Council and the Law Society of South Africa, Attorney Mbhambhu brings a
        blend of legal acumen and strategic insight to Africa Legal Advisory.
        Her commitment to delivering comprehensive, high-impact legal solutions
        makes her an invaluable asset to clients across South Africa and beyond.
      </>
    ),
  },
  {
    image: Image3,
    nam: "Fatou Mbenga",
    republic: "The Gambia Legal Counsel",
    attorney: (
      <>
        Attorney Fatou Mbenga is an accomplished business and intellectual
        property attorney based in The Gambia. She holds a Bachelor of Laws
        (LLB) from the University of The Gambia, a Barrister-at-Law
        certification from Gambia Law School, and a Master’s in Intellectual
        Property from Africa University in Zimbabwe. Known for her strategic
        insight and practical approach, <br /> <br />
        Attorney Mbenga specializes in guiding businesses through complex
        transactions, including contract negotiation, sales agreements, and
        intellectual property protections that align with her clients’ goals. In
        her role at Antouman A.B. Gaye & Co., Attorney Mbenga works extensively
        with small businesses and foreign investors, advising on regulatory
        compliance and protecting business assets in the Gambian market. Her
        expertise is complemented by her experience with global programs such as
        the WIPO-Harvard PatentX Course, which equips her with advanced
        knowledge at the intersection of intellectual property and business
        strategy. <br /> <br /> Attorney Mbenga brings a unique,
        forward-thinking perspective to Africa Legal Advisory, offering clients
        thorough guidance on business law and intellectual property across The
        Gambia and the broader African market. Her dedication to helping clients
        navigate legal complexities and protect their ventures makes her a
        valued resource for investors and entrepreneurs throughout the region.
      </>
    ),
  },
  {
    image: Image4,
    nam: "Joseph Jean",
    republic: "Egypt Legal Counsel",
    attorney: (
      <>
        Attorney Paulina Teresa Kamara is a distinguished legal professional in
        the Republic of Sierra Leone. She holds a Bachelor of Laws with Honors
        from the University of Sierra Leone and a Barrister certification from
        the Sierra Leone Law School. As Head of Legal & Corporate Affairs at the
        University of Management and Technology, she leads legal strategy and
        compliance, with expertise in business and immigration law that allows
        her to offer advisory services in corporate, environmental, human
        rights, and agricultural law. <br /> <br />
        In real estate, Attorney Kamara conducts thorough due diligence on
        transactions and land acquisitions for foreign investors, including
        African Americans seeking land and citizenship in Sierra Leone. Her
        practice also extends to the agricultural sector, where she advises on
        land use, regulatory compliance, and sustainable development, ensuring
        that clients engage in responsible investment and land practices. <br />{" "}
        <br />
        Her role as an Associate at Fornah-Sesay, Cummings, Showers & Co.
        further broadened her experience, enabling her to advise on complex
        legal matters across various sectors. A dedicated member of the Law
        Society of Sierra Leone, Attorney Kamara brings her legal expertise and
        strategic insight to Africa Legal Advisory. Her comprehensive knowledge
        of real estate, corporate law, immigration, and agriculture makes her a
        trusted advisor for clients navigating the African legal landscape.
      </>
    ),
  },
  {
    image: Image5,
    nam: "Paulina Teresa Kamara",
    republic: "Republic of Sierra Leone Legal Counsel",
    attorney: (
      <>
        Attorney Fatou Mbenga is an accomplished business and intellectual
        property attorney based in The Gambia. She holds a Bachelor of Laws
        (LLB) from the University of The Gambia, a Barrister-at-Law
        certification from Gambia Law School, and a Master’s in Intellectual
        Property from Africa University in Zimbabwe. Known for her strategic
        insight and practical approach, <br /> <br />
        Attorney Mbenga specializes in guiding businesses through complex
        transactions, including contract negotiation, sales agreements, and
        intellectual property protections that align with her clients’ goals. In
        her role at Antouman A.B. Gaye & Co., Attorney Mbenga works extensively
        with small businesses and foreign investors, advising on regulatory
        compliance and protecting business assets in the Gambian market. Her
        expertise is complemented by her experience with global programs such as
        the WIPO-Harvard PatentX Course, which equips her with advanced
        knowledge at the intersection of intellectual property and business
        strategy. <br /> <br /> Attorney Mbenga brings a unique,
        forward-thinking perspective to Africa Legal Advisory, offering clients
        thorough guidance on business law and intellectual property across The
        Gambia and the broader African market. Her dedication to helping clients
        navigate legal complexities and protect their ventures makes her a
        valued resource for investors and entrepreneurs throughout the region.
      </>
    ),
  },
  {
    image: Image6,
    nam: "Benaitwe Catherine",
    republic: "Republic of Uganda Legal Counsel",
    attorney: (
      <>
        Attorney Benaitwe Catherine is an Advocate of the High Court of Uganda
        with a robust background in corporate law, regulatory compliance,
        contract negotiation, and land acquisition. With over eight years of
        experience across sectors such as real estate, healthcare,
        telecommunications, <br /> <br /> and business compliance, she brings a
        depth of legal expertise to her practice. Formerly serving as a Legal
        Officer at the Makerere Biomedical Research Centre and a Legal Associate
        at Kawalya & Co. Advocates, Attorney Benaitwe provided strategic legal
        guidance, negotiated complex contracts, facilitated land acquisition
        processes, and ensured regulatory compliance within these organizations.{" "}
        <br /> <br /> She holds a Bachelor of Laws (LLB) from Nkumba University
        and a Postgraduate Diploma in Legal Practice from the Law Development
        Centre. Known for her analytical precision, attention to detail, and
        dedication to ethical practices, Attorney Benaitwe has contributed to
        policy development and delivered legal training to empower teams. Fluent
        in English, Runyankole, and Luganda, Attorney Benaitwe combines her
        legal expertise with cultural insight, making her highly effective in
        cross-jurisdictional consultations. Now serving as the legal counsel for
        Uganda at Global Esquire Law Firm, she provides clients with
        comprehensive support across Uganda’s evolving legal landscape.
      </>
    ),
  },
  {
    image: Image7,
    nam: "Prisca Wambui",
    republic: "Republic of Kenya Legal Counsel",
    attorney: (
      <>
        Attorney Prisca Wambui is a distinguished State Counsel for the Republic
        of Kenya, holding a Bachelor of Laws (LLB) from Moi University and a
        Post-Graduate Diploma in Law from the esteemed Kenya School of Law.{" "}
        <br /> <br />
        She is currently pursuing a Master’s in Public Policy and Administration
        at Kenyatta University, blending her extensive legal background with
        advanced public policy knowledge. With over a decade of dedicated
        service, Prisca has made significant contributions to Kenya’s legal
        landscape, particularly within the Ministry of Interior and National
        Administration and the Office of the Attorney General. Her achievements
        include her vital role in developing Kenya’s Border Management Strategic
        Plan, her leadership in shaping Kenya’s trade position under the African
        Continental Free Trade Area (AfCFTA), and her expert advisory in
        national counter-terrorism initiatives. <br /> <br />
        Renowned for her expertise in governance, international law, and risk
        advisory, Prisca has been instrumental in drafting legislation,
        negotiating international agreements, and guiding complex compliance
        efforts. As a committed member of the Law Society of Kenya, she brings
        her comprehensive legal insight to Africa Legal Advisory, offering
        clients authoritative guidance through Africa’s dynamic legal
        frameworks. Her unique blend of strategic vision, legal proficiency, and
        dedication to ethical governance positions her as an invaluable asset
        for those seeking trusted expertise in navigating Africa’s legal
        landscape.
      </>
    ),
  },
  {
    image: Image8,
    nam: "Temi Olanrewaju",
    republic: "Nigeria Legal Counsel",
    attorney: (
      <>
        Dr. Temi Olanrewaju LL.B, LL.M, S.J.D. is an accomplished attorney and
        legal scholar with an outstanding reputation in Nigeria's legal system.
        Specializing in immigration and labor law, he has successfully guided
        numerous foreign nationals and businesses through Nigeria's complex
        legal frameworks, offering strategic counsel on immigration processes,
        labor compliance, and legal operations. With a Juris Doctorate (S.J.D.)
        and Master of Laws (LL.M.), <br /> <br />
        Dr. Olanrewaju combines advanced legal expertise with practical
        experience from the National Industrial Court of Nigeria, where he
        interpreted complex legal precedents and applied international treaties
        such as the International Labour Organization treaty to support informed
        judicial decisions. <br /> <br /> As a vital asset to Africa Legal
        Advisory, he leverages his in-depth understanding of Nigeria’s legal
        landscape and cross-border legal principles to empower clients,
        contributing significantly to the Advisory’s mission of delivering
        top-tier legal support across the continent.
      </>
    ),
  },
  // Repeat for other entries
];

const Personalized = () => {
  return (
    <div className="mainPersonalized">
      <div className="Personalized">
        <h1>EXCLUSIVE TO PREMIUM PRIMER</h1>
        <p>Africa Legal Alliance</p>
        <div className="PersonalizedMain">
          <h3>
            personalized <span>legal support </span>
          </h3>
          <p>
            The Africa Legal Alliance (ALA) is the premier offering under Africa
            Legal Advisory, created for those who demand the highest level of
            legal expertise in navigating Africa’s dynamic opportunities.
            Premium subscribers gain exclusive access to Global Esquire Law
            Firm’s elite Africa-based attorneys, delivering{" "}
          </p>
          <div className="cards">
            {Immigration.map((item, index) => (
              <div className="card" key={index}>
                <h5>{item.heading}</h5>
                <p>{item.para}</p>
              </div>
            ))}
            <div className="lastCard card">
              <h5>
                Premium subscribers also benefit from one-on-one sessions with
                elite local attorneys, providing actionable advice tailored to
                their unique goals. With ALA, you’ll have the legal expertise
                and strategic support needed to thrive in Africa’s most
                rewarding markets.
              </h5>
              <p>
                Apply for our premium access today and elevate your global
                ambitions with the Africa Legal Alliance.
              </p>
            </div>
            <div className="MwiteteCards">
              {MwiteteCards.map((item, index) => (
                <div className="card" key={index}>
                  <img src={item.image} alt="person" />
                  <h4>{item.nam}</h4>
                  <h6>{item.republic}</h6>
                  <p>{item.attorney}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personalized;
