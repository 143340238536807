import React, { useEffect, useRef, useState } from "react";
import "./LandingPage.css";
import NavBar from "../NavBar/NavBar";
import SectionTwo from "./SectionTwo/SectionTwo";
import SectionThree from "./SectionThree/SectionThree";
import SectionFour from "./SectionFour/SectionFour";
import SeactionFives from "./SeactionFive/SeactionFives";
import Esquire from "./Esquire/Esquire";
import Testimonials from "./Testimonials/Testimonials";
import Primer from "./Primer/Primer";
import SectionFive from "./SectionFive/Footer";
import Personalized from "./Primer/Personalized";
import { Link } from "react-router-dom";
import { BsFillCaretRightFill, BsFillPauseFill } from "react-icons/bs";

const LandingPage = () => {
  const textInfoRef = useRef(null);
  const videoRef = useRef(null); // Reference for the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to track if the video is playing
  const [showIcon, setShowIcon] = useState(true); // State to track icon visibility

  useEffect(() => {
    const textInfoElement = textInfoRef.current;

    // Intersection Observer to trigger animation when text comes into view
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          textInfoElement.classList.add("animate-text");
        }
      },
      { threshold: 0.5 } // Adjust this value to control when the animation starts
    );

    observer.observe(textInfoElement);

    return () => observer.unobserve(textInfoElement);
  }, []);

  const handleVideoToggle = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause(); // Pause the video
      setShowIcon(true); // Show the icon again when video is paused
    } else {
      video.play(); // Play the video
      setShowIcon(true); // Show the icon before hiding it

      // Hide the icon after 1 second of video playing
      setTimeout(() => {
        setShowIcon(false);
      }, 1000);
    }
    setIsPlaying(!isPlaying); // Toggle the play/pause state
  };

  const handleMouseEnter = () => {
    setShowIcon(true); // Show the play icon on hover
  };

  const handleMouseLeave = () => {
    if (isPlaying) {
      setTimeout(() => {
        setShowIcon(false); // Hide the play icon after hover when video is playing
      }, 1000);
    }
  };

  return (
    <div className="LandingPage">
      <NavBar />
      <div className="Door">
        <div className="textInfo" ref={textInfoRef}>
          <Link to="https://globalesquire.com/">
            <span>Global Esquire Law Firm</span>
          </Link>
          <h3>
            <span>A Legal Masterclass</span> for Business, Residency, and
            Reconnection in Africa
          </h3>
          <p>
            Are you thinking about relocating or doing business in Africa? The
            continent offers incredible opportunities and a unique sense of
            freedom, yet achieving success requires careful legal planning. With
            an international law firm at your disposal, our lawyers provide the
            essential insights to help you make informed decisions in our
            six-week course. Whether you’re planning to relocate, invest in real
            estate, start a venture, or retire, our legal team guides you
            through every critical aspect of residency and business—empowering
            you to make safe, vetted, and confident choices for your prosperous
            future in Africa.
          </p>
          <Link to="/FormsOne">
            <button style={{ cursor: "pointer" }}>Apply Now</button>
          </Link>
        </div>
        <div
          className="image"
          onMouseEnter={handleMouseEnter} // Show icon on hover
          onMouseLeave={handleMouseLeave} // Hide icon after hover if playing
        >
          <video className="backgroundVideo" ref={videoRef} loop>
            <source
              src={require("../assist/LawFirm004.mp4")}
              type="video/mp4"
            />
          </video>
          <div
            className={`playIcon ${showIcon ? "visible" : "hidden"}`}
            onClick={handleVideoToggle}
          >
            <span>
              {isPlaying ? <BsFillPauseFill /> : <BsFillCaretRightFill />}
            </span>
          </div>
        </div>
      </div>
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SeactionFives />
      <Esquire />
      <Testimonials />
      <Personalized />
      <Primer />
      <SectionFive />
    </div>
  );
};

export default LandingPage;
